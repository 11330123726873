.title {
  color: #333;
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
}

.main {
  padding: 30px 15px 16px;
}

.types {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  justify-content: center;

  & .or {
    color: var(--secondary-black);
    text-align: center;
    font-size: 16px;
    font-weight: 400;

    opacity: 0.7;
  }
}

.mbank {
  display: flex;
  height: 58px;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  background: #008F67;
}

.other {
  display: flex;
  height: 57px;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 2px solid #777;
  background: var(--Color, #FFF);

  color: var(--secondary-black);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.actionButtons {
  margin-top: 20px;

  padding: 0 15px;

  & .cancel {
    width: 100%;
  }
}
