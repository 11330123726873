.popupDate {
  div[class="ant-picker-time-panel"] {
    display: none !important;
  }
}

.container {
  background-color: #F2F3F5;
  border-radius: 4px !important;
}

.date {
  width: 240px;
  border-radius: 4px !important;

  div[class="ant-picker-active-bar"] {
    display: none;
  }
}

.separator {
  background-color: #86909C;
  width: 10px;
  height: 1px;
}

@media (max-width: 768px) {
  .date {
    width: 100%;
  }
}
