.container {
  width: 100%;

  > button {
    margin-top: 16px;
    width: 100%;
    font-size: 16px;
    padding: 13px 16px !important;
  }
}
