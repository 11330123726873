.container {

  display: flex;
  flex-direction: column;
  align-items: center;

  >img {
    width: 24px;
    height: 24px;
  }

  p {
    color: #25282B;
    font-weight: 500;
  }
}
