.wrapper{
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 16px;
  align-items: center;
  flex-direction: column;
  margin: 0;
}

.title{
  display: flex;
  align-items: center;
  img{
    width: 32px;
    height: 32px;
  }
  p{
    font-size: 32px;
    font-weight: 500;
    margin-left: 8px;
  }
  margin-bottom: 8px;
    @media (max-width: 768px) {
        p {
            font-size: 20px;
          }
    }
}

.description{
  font-size: 20px;
  line-height: 130%;
  padding-bottom: 16px;
      @media (max-width: 768px) {
          p {
            font-size: 14px;
          }
        }
}
