.container {
  padding: 24px 16px 20px 24px;
  border-right: 1px solid #CFCFCF;
  min-width: 335px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
}

.currentCheckButton {
  cursor: inherit !important;
  text-align: left;
  padding-bottom: 18px;
  padding-top: 28px;
  width: 100%;
  margin-bottom: 15px;

  p .currentCheck {
    font-size: 16px;
    color: var(--primary-white);
    margin: 0;
  }

  .check {
    font-size: 32px;
    margin-top: 7px;
    font-weight: 700;
  }
}

@media (max-width: 768px) {
  .container {
    margin: 20px 0 16px;
    min-width: inherit;
    border: none;
    background-color: var(--primary-white);
    border-radius: 8px;

    & p {
      color: var(--secondary-black);
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
    }
  }
}

.achievementsModal {
  width: 639px !important;
}

.historyAchievements {
  height: 44px;
  width: 100%;
  background-color: #F4F3FE !important; 
  display: flex;
  align-items: center;
  justify-content: center;
}
