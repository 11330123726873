.image {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  width: 104px;
  height: 78px;
  margin-left: 1px;
  box-sizing: border-box;
  position: relative;

  &.selected::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    display: block;
  }

  img {
    object-fit: cover;
  }
}
