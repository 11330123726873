.container {
  width: 100%;
  padding: 16px 20px;
  border-radius: 8px;
  transition: all .2s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  min-height: 55px;
  margin: 15px 0;
  outline: solid 1.5px var(--primary-color);
  background: transparent;

  .afterSuffix {
    position: absolute;
    right: 15px;
  }

  &.disabled {
    color: var(--secondary-gray);

    .placeholder {
      color: #C5C0DB;
    }

    .input {
      color: #C5C0DB;
    }
  }

  .input:autofill,
  .input:-webkit-autofill,
  .input:-webkit-autofill:hover,
  .input:-webkit-autofill:focus,
  .input:-webkit-autofill:active {
    font-size: inherit;
    height: 100%;
    height: 90%;
    margin-top: -4px;
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  }

  .beforeSuffix {
    position: absolute;
    left: 15px;
  }

  .placeholder {
    left: 15px;
    opacity: 0;
    transition: all .2s;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 16px;
    color: #9D99AC;

    &.active {
      opacity: 1;
    }

    &.topped {
      top: 25%;
      font-size: 12px;
    }

    &.active+.input {
      top: 55%;
    }
  }

  .input {
    width: 90%;
    right: 15px;
    left: 15px;
    top: 45%;
    padding: 24px 0 16px;
    transition: top .2s;
    transform: translate(0, -50%);
    position: absolute;
    background: transparent;
    font-size: 16px;
    color: var(--secondary-text-black);
    font-weight: 400;
    caret-color: var(--primary-color);
    font-size: 16px;
  }

  &.focus {
    outline: solid 1.5px var(--primary-color);

    .placeholder {
      top: 25%;
      font-size: 12px;
    }
  }

  &:hover {
    outline: solid 1.5px var(--primary-color);
  }


  &.hasAfterSuffix .input {
    width: 60% !important;
  }

  &.hasBeforeSuffix {
    .input {
      width: 85% !important;
      margin-left: auto;
    }

    .placeholder {
      left: 11%;
    }
  }

  &.error {
    outline: solid 2px var(--primary-red) !important;

    .placeholder {
      color: var(--primary-red);
    }
  }

  &.notError {
    outline-color: var(--primary-color) !important;
    outline-width: 1px;
  }
}

@media (max-width: 820px) {
  .container {

    .beforeSuffix {
      top: 20px;
    }

    &.hasBeforeSuffix {
      .input {
        margin-left: 25px;
      }

      .placeholder {
        left: 13%;

        &.active+.input {
          top: 45%;
        }
      }
    }
  }
}
