.title {
  color: var(--secondary-black);
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 50px;

  padding: 30px 16px;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  column-gap: 15px;

  & .add {
    color: var(--primary-white);
    background-color: var(--secondary-black);
  }
}
