.text {
  color: var(--secondary-black);
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 25px;
  padding-top: 5px;
  border-bottom: 1px solid #D3D3D3;
  border-radius: 16px 16px 0px 0px;
  text-transform: uppercase;
}

.content {
  margin-top: 16px;

  .afterSuffix {
    font-size: 16px;
    color: var(--primary-color);
    cursor: pointer;
  }

  .actionButtons {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;

    .cancel {
      margin-right: 9px;
    }
  }

  .defaultCheckBox {
    color: black;
    transition: all .1s;
  }

  .errorCheckBox {
    color: var(--primary-red);
  }
}

.currentCheckButton {
  cursor: inherit !important;
  text-align: left;
  padding-bottom: 18px;
  padding-top: 28px;
  width: 100%;
  margin-bottom: 15px;

  p .currentCheck {
    font-size: 16px;
    color: var(--primary-white);
    margin: 0;
  }

  .check {
    font-size: 32px;
    margin-top: 7px;
    font-weight: 700;
  }
}

.note {
  margin-top: 23px;
  max-width: 260px;
  font-size: 12px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .policy {
    font-size: 11px !important;
  }
}
