.tabs {
  box-shadow: 0px 1px 6px rgba(36, 39, 43, 0.08), 0px 9px 19px rgba(20, 33, 52, 0.04);
  border-radius: 16px;
  background-color: var(--primary-white);
  padding: 16px;
  display: flex;
  justify-content: space-between;

  > .button {
    width: 50%;
    padding: 15px;
    border-radius: 8px;
    transition: all .2s;
    box-sizing: border-box;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    cursor: pointer;

    &.selected {
      color: var(--primary-white);
      background: var(--primary-color);
    }

    &.notSelected {
      background: var(--primary-white);
      color: var(---primary-color);
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    }
  }

  > .button:not(:last-child) {
    margin-right: 15px;
  }
}

.component {
  margin-top: 16px;
  box-shadow: 0px 1px 6px rgba(36, 39, 43, 0.08), 0px 9px 19px rgba(20, 33, 52, 0.04);
  border-radius: 16px;
  background-color: var(--primary-white);
  padding: 16px;
}
