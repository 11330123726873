.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  display: none;

  &.open {
    display: block;

  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;

    .navigation {
      display: flex;
      max-height: 100%;
      padding: 12px;
      justify-content: center;
      width: 90vw;

      .thumbs {
        display: flex;
        overflow-y: scroll;
        overscroll-behavior-y: contain;
        max-height: 100%;
        scrollbar-width: none;
        margin: 0 12px;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }



    .image {
      position: relative;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 6px;
      height: 80vh;

      .currentImage {
        padding: 0 var(--spacing-16);

        display: block;
        box-sizing: border-box;
        max-width: 100%;
        // max-height: calc(100vh - 12px);
        height: 100%;
        margin: auto;
      }
    }


  }
}

.overlay {
  background-color: #000;
  position: fixed;
  inset: 0;
  z-index: -1;

  >img {
    position: fixed;
    z-index: -1;
    user-select: none;
    pointer-events: none;
    inset: 0;
    min-width: 100vw;
    height: 100%;
    min-height: 100%;
    opacity: .45;
    filter: blur(8px);
  }
}

.navigationButton {
  display: flex;
  justify-content: center;
  padding: 4px;
  cursor: pointer;
  color: #fff;
  background-color: #0000001f;
  border-radius: var(--spacing-4);
  align-items: center;

  &.prev {
    transform: rotate(180deg);
  }
}

.close {
  position: fixed;
  top: 16px;
  right: 16px;
  width: 42px;
  height: 42px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
  }
}
