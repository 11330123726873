@media (max-width: 768px) {

  .modal {
    button[class="ant-modal-close"] {
      top: 24px !important;
    }

    div[class="ant-modal-body"] {
      padding: 25px 15px !important;
    }
  }
}
