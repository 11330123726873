.ant-modal {
  &-wrap {
      background: none !important;
  }

  &-content {
    background-color: #F5F8FF;
    padding: 0 !important;
  }

  &-title {
    padding: 20px 24px;
  }

  &-body {
    padding: 20px 24px;
  }

  &-header {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 820px) {
  .ant-modal {
    &-title {
      text-transform: uppercase;
      font-weight: 900;
    }

    &-body {
      border-top: 1px solid #D3D3D3;
      padding: 16px;
    }
  }
}
