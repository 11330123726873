.cards {
  margin-top: 30px;

  .employeeCards {
    display: flex;
    justify-content: space-between;

    .left {
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
    }

    .right {
      color: var(--primary-blue);
      font-size: 14px;
      font-weight: 700;
      cursor: pointer;
    }
  }

}
