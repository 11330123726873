.button {
  padding: 15px;
  border-radius: 8px;
  transition: all .2s;
  box-sizing: border-box;
  font-size: 16px !important;
}

.button.purple_contained {
  color: #fff;
  background: var(--primary-color) !important;

  &:disabled {
    background: #F4F3FE !important;
    cursor: inherit;
    color: #C5C0DB !important;
  }

  &:hover {
    background: #a06fc9;
  }
}

.button.purple_contained.clicked {
  background: #7c2abe !important;
}

.button.purple_outlined {
  color: var(--primary-color);
  background: transparent !important;
  border: 1px solid #965DC6;
  border: 1px solid var(--primary-color);

  &:hover {
    background: #f5f5f5 !important;
  }
}

.button.gradient_purple_contained {
  color: #fff;
  background: var(--primary-gradient);
  background: linear-gradient(258.36deg, #965DC6 0%, #842CCE 58.54%);
}

.button.black_outlined {
  background: transparent;
  border: 1px solid var(--secondary-black);
  box-shadow: 0 0 10px #F0EBF3;
  box-shadow: 0 0 10px #F0EBF3;
  color: black;

  &.clicked {
    color: #676f77;
    border: 1px solid #676f77;
  }
}

.button.black_contained {
  background: var(--secondary-black);
  color: var(--primary-white);

  &:hover {
    background: #363636;
  }

  &.clicked {
    background: #424242;
  }

  &:disabled {
    cursor: inherit;
    background: #424242;

    &:hover {
      background: #424242;
    }

  }
}

.button.secondary_purple_contained {
  background: var(--contained-purple) !important;
  color: var(--primary-white);
  border-radius: 6px;
  font-weight: 400;
}


.button.secondary_purple_contained:hover {
  background: var(--primary-color) !important;
}

.button.secondary_purple_contained.clicked {
  background: #7c2abe !important;
}

.button.white_outlined {
  background: var(--primary-white);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.button.white_text {
  background: transparent;
  color: #333;

  &:hover {
    color: #000;
  }
}
.button.pink_outlined {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background: transparent;
}


@media (max-width: 768px) {
  .button {
    padding: 12px 16px;
  }
  .button.pink_outlined {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
