@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerAnimation {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  animation: rotate 0.7s ease-in-out infinite;
  >img{
    width: 50px;
    height: 50px;
  }
}
