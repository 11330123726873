.container {

  width: 167px;
  font-size: 14px;
  background: #F4F3FE;
  border-radius: 4px;
  text-align: center;

  .toggle {
    display: flex;
    align-items: center;
    color: var(--black);
    background-color: var(--white);
    padding: 9px 16px;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: all ease-in-out 0.3s;

    &>div {
      margin-left: auto;
    }

  }


  .menu {
    animation: appear .2s linear;
    width: 100%;
    border-radius: 0 0 4px 4px;
    position: absolute;
    left: 0;
    top: 100%;
    background: #F4F3FE;
    z-index: 10;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      overflow: hidden;
      z-index: 10;
      border: 1px solid var(--black);
    }

    li {
      text-align: left;
      cursor: pointer;
      text-decoration: none;
      display: block;
      color: var(--black);
      font-size: 14px;
      padding: 9px 16px;
      transition: all ease-in-out 0.3s;
    }
  }
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.active {
  border-radius: 4px 4px 0 0;
}

.menu {
  z-index: 10;
}

.activeSelect {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  overflow: hidden;
}

@media (max-width: 768px){
  .container {
    position: relative;
  }
  .menu {
    top: 33px !important;
  }
}

@media (max-width: 820px) {
  .container {
    width: 100%;
  }
}


.select {
  border: 1px solid var(--primary-color);
}
