.select {
  height: 40px !important;
  width: 135px;

  div[class="ant-select-selector"] {
    width: 100%;
    height: 100% !important;
    border-radius: 8px;
    border: 1px solid #BBC3D9;
    border-color: #BBC3D9 !important;
    box-shadow: none !important;

    &::before {
      content: '';
      position: absolute;
      left: 10px;
      z-index: 10;
      display: block;
      width: 18px;
      height: 18px;
      background-size: cover;
    }

    span[class=ant-select-selection-item] {
      margin-left: 24px;
    }
  }

  &.white {
    div[class="ant-select-selector"] {
      &::before {
        background: url('../../../shared/assets/icons/change_lang.svg') center no-repeat;
      }
    }

    span[class=ant-select-selection-item] {
      color: #fff;
    }

    span[class=ant-select-arrow] {
      svg {
        fill: white;
      }
    }
  }

  &.black {
    div[class="ant-select-selector"] {
      &::before {
        background: url('../../../shared/assets/icons/changeLangBlack.svg') center no-repeat;
      }
    }

    span[class=ant-select-arrow] {
      svg {
        fill: black;
      }
    }
  }
}

.popup {
  border-radius: 8px;
  border: 1px solid #BBC3D9;

  background: #8B4EBF;

  div[class=ant-select-item-option-content] {
    font-size: 15px;
    color: #fff;
  }

  div[class~=ant-select-item-option-selected] {
    background: #8B4EBF !important;
  }


}

.option {
  display: flex;
  gap: 4px;
  align-items: center;
}
