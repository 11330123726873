.progress {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 24px 0 34px;
}

.progressContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progressCount {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  color: #777777;
}

.som {
  text-decoration: underline;
} 

.percent {
  color: #965DC6;
  font-size: 14px;
  font-weight: 700;
}

.goal {
  color: #842CCE;
  font-weight: 700;
}

.progressBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progressLine {
  width: 250px;
  height: 16px;
  background-color: #EEE5F6;
  border-radius: 30px;
}

.progressBackground {
  background-color: #965DC6;
  height: 100%;
  border-radius: 30px;
}
