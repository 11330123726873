.container {
  background: #FFFFFF;
  border: 1px solid #D3D3D3;
  border-radius: 8px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  padding: 10px 16px;


  .content {
    display: flex;
    align-items: center;
    width: 100%;

    .right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .text {
        width: 100%;
      }

      .logoDelete {
        cursor: pointer;
        min-width: 24px;
        min-height: 24px;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      .logoEdit {
        margin-left: 8px;
        cursor: pointer;
        min-width: 24px;
        min-height: 24px;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .logo {
      margin-right: 8px;
      min-width: 44px;
      min-height: 44px;

      > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .select {
    min-width: 20px;
    min-height: 20px;
    border: 2px solid #CDCDCD;
    border-radius: 50%;
    margin-right: 12px;
    cursor: pointer;
    position: relative;
    transition: .2s all;
  }

  .select::before {
    content: '';
    opacity: 0;
    transition: .2s opacity;
  }

  &.selected {
    border: 2px solid var(--primary-color) !important;

    .select {
      border-color: var(--primary-color);
    }

    .select::before {
      opacity: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      position: absolute;
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--primary-color);
    }
  }
}

.alert {
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .container {
    cursor: pointer;
  }

  .text {
    div {
      font-size: 13px !important;
    }
  }
}
