.buttonsContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.saveButton {
  width: 100%;
}

.editButton {
  margin-top: 5px;
  width: 100%;
}
