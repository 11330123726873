.achievements {
  max-width: 518px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 14px;
  margin: 0 auto;
}

.achievementsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .achievementsGoal, .achievementsTitle, .achievementsStatus {
    & p {
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}

.achievementsBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.achievementsStatus {
  width: 72px;
  height: 24px;
  text-align: left;

  & img {
    width: 24px;
    height: 100%;
  }
}

.achievementsTitle {
  width: 225px;
  margin-left: 15px;

  & p {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.achievementsGoal {
  width: 97px;

  & p, span {
    text-align: right;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.som {
  text-decoration: underline;
}
