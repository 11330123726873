@keyframes opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.opacityChildren {
  height: 100%;
  animation: opacity .2s ease-out;
}
