.container {
  width: 100%;
  height: 100%;
  overflow: auto;

  .infinityContainer {
    width: 100%;
    display: grid;
    gap: 32px;
    overflow: scroll;

    &.rev {
      grid-template-columns: 1fr;
    }

    &.tips {
      grid-template-columns: repeat(3, 1fr);

    }
  }
}

.skeleton {
  height: 110px !important;

  > span {
    height: 100%;
  }
}

@media (max-width: 768px) {

  .infinityContainer {
    display: flex !important;
    flex-direction: column;
    gap: 16px;
  }
}
