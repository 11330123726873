.container {
  padding: 20px 15px 15px 15px;
  width: 280px;
  max-height: 104px;
  box-shadow: 0px 0px 10px rgba(240, 235, 243, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  .date {
    color: #222222;
    font-size: 16px;
    text-overflow: ellipsis;
  }

  .amount {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 32px;
    color: #222222;
    font-weight: 700;
  }
}

.container.green {
  background: rgba(93, 198, 97, 0.15);
}

.container.gray {
  background: rgba(145, 145, 145, 0.15);
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    height: 100%;
    max-height: 94px !important;
    min-height: 94px;
  }
}
