.text {
  color: var(--secondary-black);
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 25px;
  padding-top: 5px;
  border-bottom: 1px solid #D3D3D3;
  border-radius: 16px 16px 0px 0px;
  text-transform: uppercase;
}

.content {
  margin-top: 20px;
}

.actionButtons {
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;

  .cancel {
    margin-right: 9px;
  }
}
