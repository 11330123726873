.container {
  background: rgba(150, 93, 198, 0.05);
  box-shadow: 0px 0px 10px rgba(240, 235, 243, 0.25);
  border-radius: 8px;
  padding: 16px;

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .top {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      flex-grow: 2;

      .author {
        .full_name {
          color: var(--secondary-black);
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
        }
      }

      .reviewsNSmile {
        display: flex;
        column-gap: 6px;
        align-items: center;
      }

      .reviews {
        display: flex;
        width: 300px;
        column-gap: 6px;
        height: 34px;

        cursor: pointer;
        -ms-overflow-style: none;
        overflow-y: auto;
        scrollbar-width: none;
        /* IE and Edge */
        /* Firefox */

        .review {
          border-radius: 100px;
          background: rgba(150, 93, 198, 0.15);
          padding: 6px 16px;
          font-size: 12px;
          font-weight: 500;
          line-height: 22px;
          white-space: nowrap;
          display: flex;
          align-items: center;
        }
      }

      .reviews::-webkit-scrollbar {
        display: none;
      }
    }

    .mid {
      color: var(--secondary-black);
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 16px;
    }

    .bottom {
      display: inline-block;
      align-self: flex-end;
    }
  }
}

@media (max-width: 768px) {
  .top {
    flex-direction: column;

    .author {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .reviewsNSmile {
      align-items: center;
    }

    & .reviews {
      height: 34px;
      padding: 16px 0;
      align-items: center;

      width: 264px;
    }
  }
}
