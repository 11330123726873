.tabs {
  position: absolute;
  top: -55px;
  right: 0;
  display: flex;
  align-items: center;
}

.notFoundContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .notFoundImg {
    width: 360px;
    height: 360px;
  }

  .textNotFound {
    color: #6A6A6A;
    font-size: 24px;
    margin-top: 16px;
  }
}


.buttons {
  margin-left: 16px;
  display: flex;

  .tabFilter {
    width: auto !important;
    padding: 8px 16px !important;
  }

  .tabFilter:not(:last-child) {
    margin-right: 8px !important;
  }
}

.visible {
  transition: all .2s;
  opacity: 1;
  height: 100%;
  z-index: 10;
}

.hidden {
  height: 0px;
  position: absolute;
  opacity: 0;
  z-index: -1;
}

@media (max-width: 768px) {
  .notFoundContainer {
    position: relative;

    .notFoundImg {
      width: 100%;
      object-fit: cover;
      height: 300px;
    }

    .textNotFound {
      position: absolute;
      bottom: 0;
      font-size: 20px;
      margin-top: 0px;
    }
  }

  .tabs {
    position: inherit;
    margin-bottom: 15px;
    flex-direction: column;
    align-items: stretch;

    .buttons {
      margin-top: 15px;
      margin-left: 0;
    }
  }
}
