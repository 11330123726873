.items {
  display: flex;
  gap: 6px;

  .item {
    width: 50px;
    height: 50px;
    cursor: pointer;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
}

.mobileGallery {
  height: 100vh;
  display: flex;
  align-items: center;
}

.slideMain {
  width: 90%;
  position: relative;
  height: 191px;

  >img {
    width: 100% !important;
    object-fit: cover;
  }
}
