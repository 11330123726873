.modal {
  button[class="ant-modal-close"] {
    width: 35px;
    height: 35px;
  }

  div[class~="ant-modal-body"] {
    border-top: none !important;
  }
}
